import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const Hifu = () => {
  const data = useStaticQuery(graphql`
    {
      hifuLogo: file(relativePath: { eq: "hifu-logo.png" }) {
        publicURL
      }
      hifuBg: file(relativePath: { eq: "hifu-bg.jpeg" }) {
        publicURL
      }
      bgImage: file(relativePath: { eq: "bg-image.png" }) {
        publicURL
      }
      hifuCheck: file(relativePath: { eq: "hifu-check.svg" }) {
        publicURL
      }
      star: file(relativePath: { eq: "star.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <section>
      <div className="py-3 w-full bg-blue-300 bg-opacity-80">
        <h1 className="text-center text-white text-lg font-bold lg:text-2xl">
          HIFU（ウルセラ）のご案内
        </h1>
      </div>
      <div className="relative">
        <div className="w-full h-104 lg:h-160">
          <img
            className="w-full h-full hifu-bg object-cover"
            src={data.hifuBg.publicURL}
            alt="HIFU　背景画像"
          />
        </div>
        <div className="absolute bottom-24 left-8 md:left-16 lg:left-40">
          <img className="w-24 lg:w-40" src={data.hifuLogo.publicURL} alt="" />
        </div>
        <div className="absolute inset-x-0 top-8 px-4 lg:top-16">
          <h2 className="text-center text-gray-600 text-xl font-bold lg:text-5xl">
            FDA公認【効果・持続性 No.1】
          </h2>
        </div>
      </div>
      <div className="flex gap-x-4 items-center justify-center transform -translate-y-1/2 lg:gap-x-8">
        <div className="w-24 h-24 bg-white rounded-full drop-shadow-xl filter lg:w-48 lg:h-48">
          <span className="block mt-4 text-center text-primary-gold text-xs font-bold tracking-wider lg:text-xl lg:mt-8">
            Point 1
          </span>
          <span className="trackig-wider block mt-1 text-center text-primary-gold text-sm font-bold lg:text-2xl lg:mt-4">
            ほうれい線
            <br />
            解消
          </span>
        </div>
        <div className="w-24 h-24 bg-white rounded-full drop-shadow-xl filter lg:w-48 lg:h-48">
          <span className="block mt-4 text-center text-primary-gold text-xs font-bold tracking-wider lg:text-xl lg:mt-8">
            Point 2
          </span>
          <span className="trackig-wider block mt-1 text-center text-primary-gold text-sm font-bold lg:text-2xl lg:mt-4">
            しわ・たるみ
            <br />
            改善
          </span>
        </div>
        <div className="w-24 h-24 bg-white rounded-full drop-shadow-xl filter lg:w-48 lg:h-48">
          <span className="block mt-4 text-center text-primary-gold text-xs font-bold tracking-wider lg:text-xl lg:mt-8">
            Point 2
          </span>
          <span className="trackig-wider block mt-1 text-center text-primary-gold text-sm font-bold lg:text-2xl lg:mt-4">
            小顔
            <br />
            引き締め
          </span>
        </div>
      </div>
      <div>
        <div style={{ backgroundImage: `url(${data.bgImage.publicURL})` }}>
          <div className="py-3 w-full bg-blue-300 bg-opacity-80">
            <h3 className="text-center text-white text-lg font-bold lg:text-2xl">
              こんなお悩みありませんか？
            </h3>
          </div>
          <ul className="p-8 px-6 lg:w-fit-content mx-auto">
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                顔は痩せているが、たるみが気になる
              </p>
            </li>
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                顔の下半分のたるみが気になる
              </p>
            </li>
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                フェイスラインがたるんで見える
              </p>
            </li>
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                小じわの改善・肌のハリが欲しい
              </p>
            </li>
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                顔を持ち上げると若く見える
              </p>
            </li>
            <li className="flex gap-x-4 items-center mb-4 lg:gap-x-8 lg:mb-8">
              <img
                className="w-5 lg:w-8"
                src={data.hifuCheck.publicURL}
                alt="チェックマーク"
              />
              <p className="mark text-gray-700 font-bold tracking-wider lg:text-2xl">
                切らずに、たるみをどうにかしたい
              </p>
            </li>
          </ul>
          <div>
            <div className="mb-8 px-6 lg:w-fit-content mx-auto">
              <div className="w-full h-px bg-primary-gold" />
              <div className="py-4">
                <h4 className="px-8 text-center text-primary-gray text-xl font-bold tracking-wider lg:px-16 lg:text-3xl">
                  これらの悩みの
                  <br />
                  一番の改善方法として
                </h4>
              </div>
              <div className="w-full h-px bg-primary-gold" />
            </div>
            <div className="mb-8 w-fit-content mx-auto lg:mb-16">
              <StaticImage
                className="w-full h-auto object-cover lg:w-140 mx-auto"
                src="../../content/assets/hifu-image-1.jpeg"
                quality="100"
              />
            </div>
            <div className="mb-12">
              <h5 className="text-center text-2xl lg:text-4xl">
                HIFU（ウルセラ）で
                <br />
                切らないフェイスリフト
              </h5>
            </div>
            <div className="pb-16 px-4 max-w-4xl mx-auto">
              <h6 className="mb-4 text-primary-gold text-lg font-bold lg:text-2xl lg:mb-8">
                HIFU（ウルセラ）とは？
              </h6>
              <div className="mb-4 mx-auto w-2/3 lg:mb-8">
                <StaticImage
                  className="w-full h-auto object-cover"
                  src="../../content/assets/hifu-image-2.jpeg"
                  quality="100"
                />
              </div>
              <p className="mb-12 text-primary-gray text-sm font-medium tracking-wider leading-loose lg:text-lg lg:mb-16">
                ウルセラは、
                <span className="text-primary-gold font-bold">
                  リフトアップ治療機の中で唯一、皮膚の一番深い層のSMAS層まで強力に引き上げる
                </span>
                ことができる新しいマシンです。
                <br />
                <span className="text-primary-gold font-bold">
                  ウルセラはFDAで認可されている画期的なたるみの治療方法
                </span>
                です。
                <br />
                <br />
                高密度の超音波をあてることで、SMAS層（表在性筋膜）を引き上げてたるみを解消します。
                <br />
                SMAS層（表在性筋膜）を引き上げる治療としまして外科的手術のフェイスリフトがありますが、
                <span className="text-primary-gold font-bold">
                  ウルセラは切開せず照射だけでリフトアップ効果が得られる治療法
                </span>
                を生み出しました。
                <br />
                <br />
                そのため、別名「
                <span className="text-primary-gold font-bold">
                  切らないフェイスリフト
                </span>
                」とも言われています。
              </p>
            </div>
          </div>
        </div>
        <div className="bg-primary-pink bg-opacity-40 mb-16">
          <div>
            <div className="w-full h-2 bg-[#F58CB3]"></div>
            <div className="w-full h-2 bg-[#F19CBB]"></div>
            <div className="w-full h-2 bg-primary-pink"></div>
          </div>
          <div className="max-w-4xl mx-auto">
            <h6 className="my-8 font-bold text-lg text-center text-[#E11677]">
              ウルセラと他のHIFU治療機との違い
            </h6>
            <div className="overflow-x-scroll px-4 mb-16">
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 lg:w-40" />
                <div className="shrink-0 w-40 py-2 bg-primary-gold lg:w-56">
                  <span className="text-white font-bold block text-center">
                    ウルセラ
                  </span>
                </div>
                <div className="shrink-0 w-40 py-2 bg-primary-gold bg-opacity-60 lg:w-56">
                  <span className="text-white font-bold block text-center">
                    ダブロ
                  </span>
                </div>
                <div className="shrink-0 w-40 py-2 bg-primary-gold bg-opacity-60 lg:w-56">
                  <span className="text-white font-bold block text-center">
                    ハイフシャワー
                  </span>
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  機械
                </div>
                <div className="shrink-0 w-40 py-8 px-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <StaticImage
                    className="w-full h-full"
                    src="../../content/assets/hifu-image-3.png"
                    quality="100"
                  />
                </div>
                <div className="shrink-0 w-40 py-8 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <StaticImage
                    className="w-full h-full"
                    src="../../content/assets/hifu-image-9.png"
                    quality="100"
                  />
                </div>
                <div className="shrink-0 w-40 py-8 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <StaticImage
                    className="w-full h-full"
                    src="../../content/assets/hifu-image-8.png"
                    quality="100"
                  />
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 p-4 text-center bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  焦点の正確さ
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="font-bold text-lg text-[#E11677]">
                    かなり精密
                  </span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">精密</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">精密</span>
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 p-4 text-center bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  熱量
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="font-bold text-lg text-[#E11677]">強い</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">弱い</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">強い</span>
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 p-4 text-center bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  リフト持続力
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <div className="flex items-center justify-center gap-x-0.5">
                    <img className="w-6" src={data.star.publicURL} alt="" />
                    <img className="w-6" src={data.star.publicURL} alt="" />
                    <img className="w-6" src={data.star.publicURL} alt="" />
                    <img className="w-6" src={data.star.publicURL} alt="" />
                    <img className="w-6" src={data.star.publicURL} alt="" />
                  </div>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <div className="flex items-center justify-center gap-x-0.5">
                    <img
                      className="w-6 opacity-50"
                      src={data.star.publicURL}
                      alt=""
                    />
                    <img
                      className="w-6 opacity-50"
                      src={data.star.publicURL}
                      alt=""
                    />
                  </div>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <div className="flex items-center justify-center gap-x-0.5">
                    <img
                      className="w-6 opacity-50"
                      src={data.star.publicURL}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 p-4 text-center bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  治療頻度
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="font-bold text-lg text-[#E11677]">
                    6ヶ月〜1年
                  </span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">2ヶ月〜6ヶ月</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">5ヶ月〜6ヶ月</span>
                </div>
              </div>
              <div className="flex gap-x-1 mb-1">
                <div className="shrink-0 w-24 p-4 text-center bg-white bg-opacity-50 flex items-center justify-center lg:w-40">
                  痛み
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="font-bold text-lg text-[#E11677]">ある</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">少ない</span>
                </div>
                <div className="shrink-0 w-40 p-4 bg-white bg-opacity-50 flex items-center justify-center lg:w-56">
                  <span className="text-lg">少ない</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full h-2 bg-primary-pink"></div>
            <div className="w-full h-2 bg-[#F19CBB]"></div>
            <div className="w-full h-2 bg-[#F58CB3]"></div>
          </div>
        </div>
        <div className="px-4 mb-16 lg:max-w-4xl mx-auto">
          <div className="flex justify-between">
            <h6 className="text-[#E11677] font-bold text-2xl lg:text-4xl">
              ウルセラによる
              <br />
              4つのメリットを紹介！
            </h6>
            <span className="text-primary-pink font-bold text-3xl opacity-80 tracking-widest writing-mode-vertical lg:text-4xl">
              FOUR REASONS
            </span>
          </div>
          <div className="mb-16">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="shrink-0 w-28 h-28 rounded-full bg-primary-gold font-bold tracking-widest flex flex-col items-center justify-center">
                <span className="mt-2 text-sm text-white">MERIT</span>
                <span className="text-4xl text-white">01</span>
              </div>
              <h6 className="font-bold text-primary-gray text-2xl pr-8">
                土台からのリフトアップ！
              </h6>
            </div>
            <p className="text-sm tracking-widest leading-loose mb-8 lg:text-lg">
              肌の土台であるSMAS（表在性筋膜）のゆるみが肌のたるみやしわの原因になっています。ウルセラは
              <span className="text-primary-gold font-bold">
                一番深い層の筋膜までパワーが届き、ピンポイントで熱凝固させることによりリフトアップを実現します。
              </span>
            </p>
            <div className="w-fit-content mx-auto">
              <StaticImage
                className="w-full h-full lg:w-140"
                src="../../content/assets/hifu-image-4.jpeg"
                quality="100"
              />
            </div>
          </div>
          <div className="mb-16">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="shrink-0 w-28 h-28 rounded-full bg-primary-gold font-bold tracking-widest flex flex-col items-center justify-center">
                <span className="mt-2 text-sm text-white">MERIT</span>
                <span className="text-4xl text-white">02</span>
              </div>
              <h6 className="font-bold text-primary-gray text-2xl pr-8">
                コラーゲン再生で肌が若返り！
              </h6>
            </div>
            <div className="lg:flex items-center gap-x-8">
              <div className="px-4 mb-8 lg:w-1/3 flex-shrink-0">
                <StaticImage
                  className="w-full h-full rounded-full"
                  src="../../content/assets/hifu-image-5.jpeg"
                  quality="100"
                />
              </div>
              <p className="text-sm tracking-widest leading-loose lg:text-lg">
                ウルセラは
                <span className="text-primary-gold font-bold">
                  コラーゲンの生成に最も適している60〜70°Cの熱をピンポイントで照射し、新しいコラーゲンを作り出していきます。
                </span>
                肌の表面・真皮層・筋膜にコラーゲンが増えることで、
                <span className="text-primary-gold font-bold">
                  肌の若返りが期待できます。
                </span>
              </p>
            </div>
          </div>
          <div className="mb-16">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="shrink-0 w-28 h-28 rounded-full bg-primary-gold font-bold tracking-widest flex flex-col items-center justify-center">
                <span className="mt-2 text-sm text-white">MERIT</span>
                <span className="text-4xl text-white">03</span>
              </div>
              <h6 className="font-bold text-primary-gray text-2xl pr-8">
                注射やメスは使わない！
              </h6>
            </div>
            <div className="lg:flex flex-row-reverse items-center gap-x-8">
              <div className="px-4 mb-8 lg:w-1/3 flex-shrink-0">
                <StaticImage
                  className="w-full h-full rounded-full"
                  src="../../content/assets/hifu-image-6.jpeg"
                  quality="100"
                />
              </div>
              <p className="text-sm tracking-widest leading-loose lg:text-lg">
                通常のリフトアップ治療は美容外科などでメスや注射を用いた手術を行うことが一般的です。ウルセラの場合は
                <span className="text-primary-gold font-bold">
                  照射のみで同等の効果が期待できます。
                </span>
                その効果は現在世界中で認められており、
                <span className="text-primary-gold font-bold">
                  ウルセラは米国FDAで初めて「リフトアップ」の効果があると認められた唯一の医療機器
                </span>
                です。
              </p>
            </div>
          </div>
          <div className="mb-16">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="shrink-0 w-28 h-28 rounded-full bg-primary-gold font-bold tracking-widest flex flex-col items-center justify-center">
                <span className="mt-2 text-sm text-white">MERIT</span>
                <span className="text-4xl text-white">04</span>
              </div>
              <h6 className="font-bold text-primary-gray text-2xl pr-8">
                ダウンタイムが少ない！
              </h6>
            </div>
            <div className="lg:flex items-center gap-x-8">
              <div className="px-4 mb-8 lg:w-1/3 flex-shrink-0">
                <StaticImage
                  className="w-full h-full rounded-full"
                  src="../../content/assets/hifu-image-7.jpeg"
                  quality="100"
                />
              </div>
              <p className="text-sm tracking-widest leading-loose lg:text-lg">
                施術直後は患部が軽く赤みが出ることはありますが、数時間で消退することがほとんどです。稀に軽い腫れが見られることがありますが、こちらも数日で徐々に消退していきます。
                <span className="text-primary-gold font-bold">
                  ウルセラでは肌に傷がつかないので、当日からメイクも可能です。
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="w-fit-content mx-auto mb-8">
            <h3 className="text-xl font-bold text-primary-gold tracking-widest mb-1 md:text-2xl md:mb-2">
              症例写真
            </h3>
            <div className="w-full h-px bg-primary-gold" />
          </div>
          <div className="px-4 flex items-center justify-center mb-8">
            <StaticImage
              className="h-full text-center lg:h-64 lg:w-120"
              src="../../content/assets/hifu_beforeafter1.jpeg"
              quality="100"
            />
          </div>
          <div className="px-4 flex items-center justify-center mb-8">
            <StaticImage
              className="h-full text-center lg:h-64 lg:w-120"
              src="../../content/assets/hifu_beforeafter2.jpeg"
              quality="100"
            />
          </div>
          <div className="px-4 flex items-center justify-center mb-8">
            <StaticImage
              className="h-full text-center lg:h-64 lg:w-120"
              src="../../content/assets/hifu_beforeafter3.jpeg"
              quality="100"
            />
          </div>
          <p className="text-right text-gray-400 text-xs px-4 mb-8">
            ※写真提供：Dr. Kyle Seo
          </p>
          <div className="mb-16"></div>
        </div>
        <div className="mb-16 px-4 max-w-4xl mx-auto">
          <div className="w-fit-content mx-auto mb-8">
            <h3 className="text-xl font-bold text-primary-gold tracking-widest mb-1 md:text-2xl md:mb-2">
              FAQ
            </h3>
            <div className="w-full h-px bg-primary-gold" />
          </div>
          <div className="bg-[#F6F2EF] p-4 mb-4">
            <div className="flex items-center gap-x-4 mb-6">
              <div className="w-10 h-10 rounded-full bg-primary-pink flex items-center justify-center">
                <span className="font-bold text-xl text-white">Q</span>
              </div>
              <p className="tracking-wider font-bold text-primary-gray">
                痛みはどのくらいですか？
              </p>
            </div>
            <div className="flex gap-x-4">
              <div className="w-10 h-10 flex-shrink-0 rounded-full bg-[#F58CB3] flex items-center justify-center">
                <span className="font-bold text-xl text-white">A</span>
              </div>
              <p className="tracking-wider leading-relaxed text-sm text-primary-gray">
                当院ではウルセラの治療には麻酔を使用しますので、痛みを感じる方は少ないです。
                <br />
                麻酔代は施術代金に含まれています。
              </p>
            </div>
          </div>
          <div className="bg-[#F6F2EF] p-4 mb-4">
            <div className="flex items-center gap-x-4 mb-6">
              <div className="w-10 h-10 rounded-full bg-primary-pink flex items-center justify-center">
                <span className="font-bold text-xl text-white">Q</span>
              </div>
              <p className="tracking-wider font-bold text-primary-gray">
                効果はどのくらい持続しますか？
              </p>
            </div>
            <div className="flex gap-x-4">
              <div className="w-10 h-10 flex-shrink-0 rounded-full bg-[#F58CB3] flex items-center justify-center">
                <span className="font-bold text-xl text-white">A</span>
              </div>
              <p className="tracking-wider leading-relaxed text-sm text-primary-gray">
                ウルセラのリフトアップ効果は約半年から１年間持続します。効果の現れ方には個人差がございます。
              </p>
            </div>
          </div>
          <div className="bg-[#F6F2EF] p-4 mb-4">
            <div className="flex items-center gap-x-4 mb-6">
              <div className="w-10 h-10 rounded-full bg-primary-pink flex items-center justify-center">
                <span className="font-bold text-xl text-white">Q</span>
              </div>
              <p className="tracking-wider font-bold text-primary-gray">
                ダウンタイムはありますか？
              </p>
            </div>
            <div className="flex gap-x-4">
              <div className="w-10 h-10 flex-shrink-0 rounded-full bg-[#F58CB3] flex items-center justify-center">
                <span className="font-bold text-xl text-white">A</span>
              </div>
              <p className="tracking-wider leading-relaxed text-sm text-primary-gray">
                施術直後は患部が軽く赤みが出ることはありますが、数時間で消退することがほとんどです。
                <br />
                当日からメイクが可能です。
              </p>
            </div>
          </div>
          <div className="bg-[#F6F2EF] p-4 mb-4">
            <div className="flex items-center gap-x-4 mb-6">
              <div className="w-10 h-10 rounded-full bg-primary-pink flex items-center justify-center">
                <span className="font-bold text-xl text-white">Q</span>
              </div>
              <p className="tracking-wider font-bold text-primary-gray">
                副作用はありますか？
              </p>
            </div>
            <div className="flex gap-x-4">
              <div className="w-10 h-10 flex-shrink-0 rounded-full bg-[#F58CB3] flex items-center justify-center">
                <span className="font-bold text-xl text-white">A</span>
              </div>
              <p className="tracking-wider leading-relaxed text-sm text-primary-gray">
                稀に、コメド、毛嚢炎、埋没毛、膿疱、ほくろ、しみの部分が薄くなる・消える・一時的に濃くなる、色素沈着や内出血、灼熱感、瘢痕が生じることがあります。
              </p>
            </div>
          </div>
        </div>
        <div className="mb-16 px-4 max-w-4xl mx-auto">
          <div className="w-fit-content mx-auto mb-8">
            <h3 className="text-xl font-bold text-primary-gold tracking-widest mb-1 md:text-2xl md:mb-2">
              料金表
            </h3>
            <div className="w-full h-px bg-primary-gold" />
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【しわ・たるみ】HIFU（ウルセラリフト）
              </h5>
            </div>
            <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
              <span className="w-full text-sm md:text-base">【全顔】</span>
              <span className="block w-full text-right text-sm md:text-base">
                ¥166,000
              </span>
            </div>
            <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
              <span className="w-full text-sm md:text-base">
                【全顔 + 顎下】
              </span>
              <span className="block w-full text-right text-sm md:text-base">
                ¥187,000
              </span>
            </div>
            <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
              <span className="w-full text-sm md:text-base">
                【ウルセラアイリフト】
              </span>
              <span className="block w-full text-right text-sm md:text-base">
                ¥121,000
              </span>
            </div>
            <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
              <span className="w-full text-sm md:text-base">
                【ウルセラチークリフト】
              </span>
              <span className="block w-full text-right text-sm md:text-base">
                ¥143,000
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
