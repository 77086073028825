import React from "react";
import { Container, NewsBlog } from "../../components";
import { Hifu } from "../../menu";
import Seo from "../../components/seo";

const TreatmentPage = () => {
  return (
    <Container>
      <Seo title="HIFU（ウルセラ）" />
      <div className="bg-white">
        <Hifu />
        <NewsBlog />
      </div>
    </Container>
  );
};

export default TreatmentPage;
